import { Navbar } from 'react-bootstrap';
import styles from 'styles/layout.module.scss';

import * as logos from 'assets/logos';

export default function MainNav() {
  return (
    <>
      <div className={styles.header}>
        <Navbar id="header" className={styles.container} expand={false}>
          <Navbar.Brand className={styles.brand}>Research
            <span className={styles.italic}>@</span>
            <a href="https://www.ripl.org" target="_window">
              <img src={logos.ripl} alt="Research Improving People's Lives (RIPL)" className={styles.logo} />
            </a>
          </Navbar.Brand>
          <span id="nav-breadcrumb" />
        </Navbar>
      </div>
      <div className={styles.banner}>
        <div className={styles.container}>
          <a href="https://www.ripl.org/" target="_window">
            Research Improving People's Lives
          </a>
          is a non-profit, social impact, tech organization that works with governments to help them use data, science, and technology to improve policy and lives.
        </div>
      </div>
    </>
  );
}