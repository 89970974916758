import PropTypes from "prop-types";
import { Badge, Accordion, Card } from 'react-bootstrap';
import { ExternalLink } from "components/common";
import styles from 'styles/sockit.module.scss';

const SockitResultCard = ({ soc }) => {

    const prob = Math.round(soc.prob.toFixed(2) * 100);

    const getProbClass = () => {
        if (prob >= 40) {
            return "success";
        } else if (prob > 10) {
            return "warning";
        } else {
            return "danger";
        }
    }

    const cardHeader = [
        <h3 key="jobName" className={"h5"}>{soc.title}</h3>,
        <span key="socCode" className={styles.socCode}>SOC Code: {[soc.soc.slice(0, 2), "-", soc.soc.slice(2)].join('')}
            <Badge key="probability" bg={getProbClass()} className={styles.badge}><span>{`${prob}%`}</span> match</Badge></span>,
    ];
    const cardBody = formatCardBody(soc);

    return cardBody.length
        ? <Accordion.Item as={"article"} className={styles.resultCard} eventKey={soc.soc}>
            <Accordion.Header as={"header"}>
                <div className={styles.resultCardHead}>
                    {cardHeader}
                </div>
            </Accordion.Header>
            <Accordion.Body className={styles.resultCardBody}>
                {cardBody}
            </Accordion.Body>
        </Accordion.Item>
        : <Card className={styles.resultCard}>
            <div className={`accordion-button collapsed ${styles.detachedHead}`}>
                <div className={styles.resultCardHead}>
                    {cardHeader}
                </div>
            </div>
        </Card>
}

const CareerVideo = ({ title, soc_code, url }) => {
    const base_url = url.substring(0, url.length - 4)
    const caption_url = base_url.replace('OccVids/OccupationVideos', 'CaptionFiles');
    return (
        <video
            src={url}
            poster={base_url + '.jpg'}
            id={`career_video_${soc_code}`}
            title={title}
            controls={true}
            controlsList="nodownload"
            crossOrigin="anonymous"
        >
            <track
                src={caption_url + '.vtt'}
                id={`career_video_transcript_en_${soc_code}`}
                label="English"
                kind="subtitles"
                srcLang="en"
                default={true}
            />
            <track
                src={caption_url + '_es.vtt'}
                id={`career_video_transcript_es_${soc_code}`}
                label="Español"
                kind="subtitles"
                srcLang="es"
            />
        </video>
    );
};

const formatCardBody = (soc) => {
    const cardContents = [];

    const doesValueExist = (property) => soc.hasOwnProperty(property) && (soc[property]).toLowerCase() !== "none"

    if (doesValueExist('description')) {
        cardContents.push(<p key="description">{soc.description}</p>)
    }

    if (doesValueExist('video')) {
        cardContents.push(
            <div key="video" className={styles.videoContainer}>
                <CareerVideo
                    title={soc.title}
                    soc_code={soc.soc}
                    url={soc.video} />
            </div>)
    }

    if (doesValueExist('salary_median')) {
        const getDollars = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        });
        cardContents.push(<div key="salary" className={styles.cardDetail}>
            <span className={styles.cardLabel}>Median Salary:</span>
            <span>{getDollars.format(soc.salary_median)}</span>
        </div>)
    }

    if (doesValueExist('bright_outlook')) {
        cardContents.push(<div key="outlook" className={styles.cardDetail}>
            <span className={styles.cardLabel}>Bright Outlook:</span>
            <span className={`${styles.outlook} ${styles[(soc.bright_outlook.split(' ')[0].toLowerCase())]}`}>{soc.bright_outlook}</span>
        </div>)
    }

    if (doesValueExist('typical_training')) {
        cardContents.push(<div key="training" className={styles.cardDetail}>
            <span className={styles.cardLabel}>Typical training at entry:</span>
            <ul>{soc.typical_training.split(', ').map((item, idx) => (
                <li key={`item-${idx}`}>{item}</li>
            ))}</ul>
        </div>)
    }

    if (doesValueExist('learn')) {
        cardContents.push(<ExternalLink
            key="link"
            url={soc.learn}
            linkText={`Learn more about the ${soc.title} career`}
            wrapperClassName={styles.careerLink} />)
    }

    return cardContents;
}

SockitResultCard.propTypes = {
    soc: PropTypes.shape({
        soc: PropTypes.string,
        prob: PropTypes.number,
        title: PropTypes.string,
        learn: PropTypes.string,
        description: PropTypes.string,
        video: PropTypes.string,
        bright_outlook: PropTypes.string,
        typical_training: PropTypes.string,
    })
}

export default SockitResultCard;