import { useLocation } from 'react-router-dom';
import * as ROUTES from 'lib/routes';

import { Container } from 'react-bootstrap';
import styles from 'styles/layout.module.scss';
import * as logos from 'assets/logos';

const background = '/images/ripple-banner.png';

const MainFooter = () => {

  return (
    <footer>
      <div id="footer" className={styles.footer}>
        <ToolFooters />
        <div className={styles.divider} style={{ backgroundImage: `url(${background})` }} />
        <div className={styles.footerBackground}>
          <Container className={styles.container}>
            <a href="https://www.ripl.org" target="_window">
              <img src={logos.ripl} alt="Research Improving People's Lives (RIPL)" className={styles.logo} />
            </a>
            <div className={styles.text}>
              <div className={styles.places}>
              {`PROVIDENCE | NEW YORK | ANNAPOLIS | CONCORD | MINNEAPOLIS | PORTLAND (ME)`}
              </div>
              <p className={styles.small}>
                Copyright &copy; 2021-2022 Innovative Policy Lab d/b/a Research Improving People's Lives (RIPL). All Rights Reserved.
              </p>
              <p className={styles.small}>
                <a href="https://www.ripl.org/privacy-terms-copyright/">
                  Privacy Policy and Terms of Use
                </a>
              </p>
            </div>
          </Container>
        </div>
      </div>
    </footer>
  );
}

export default MainFooter;

const ToolFooters = () => {
  const { pathname } = useLocation();

  if (pathname.includes(ROUTES.SOCKIT.HOME)) {
    return <div className={styles.sockitFooter}>
      <header className={styles.bold}>
        Brought to you by:
      </header>
      <ul className={`${styles.footerLogoContainer} ${pathname.includes(ROUTES.SOCKIT.RESULTS) ? styles.footerLogoContainer__results : ''}`}>
        <li>
          <div className={styles.footerLogo}>
            <a href="https://www.ripl.org" target="_window">
              <img
                src={logos.ripl}
                alt="Research Improving People's Lives (RIPL)"
                className={styles.logoRIPL} />
            </a>
          </div>
        </li>
        <li>
          <div className={styles.footerLogo}>
            <a href="https://nlxresearchhub.org/" target="_window">
              <img
                src={logos.nlx}
                alt="NLx Research Hub"
                className={styles.logoNLx} />
            </a>
          </div>
        </li>
      </ul>
    </div >
  } else {
    return null;
  }
}