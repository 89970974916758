import { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { sortKeys } from 'lib/utils';
import { FILTER_CATEGORIES } from 'lib/vars';
import { Filter, FilterLabel } from 'components/common';


const useFilters = (productList = []) => {

    const filters = countFilterLabelsByCategory(productList);
    const hasFilters = (Object.keys(filters).length > 0);
    const canBeFiltered = hasFilters && productList.length;
    const [isFilterActive, setIsFilterActive] = useState(false);

    const allFiltersRef = useRef(0);
    const [activeProductList, setActiveProductList] = useState(productList);
    const [activeFilterList, setActiveFilterList] = useState([]);

    /*-- controls for filter menu --*/
    const handleFilter = (label) => {
        if (activeFilterList.length === allFiltersRef.current.length) {
            setActiveFilterList([label]);
        } else if (!activeFilterList.includes(label)) {
            setActiveFilterList(prevState => ([...prevState, label]));
        } else {
            setActiveFilterList(prevState => prevState.splice(prevState.indexOf(label), 1));
        };

        if (!isFilterActive) {
            setIsFilterActive(true);
        }
    };

    const handleFilterReset = useCallback(() => {
        const flattenedFilters = [].concat.apply([], Object.values(filters));
        setActiveFilterList(flattenedFilters);
        if (isFilterActive) {
            setIsFilterActive(false);
        }

        //initial load:
        if (allFiltersRef.current.length !== flattenedFilters.length) {
            allFiltersRef.current = flattenedFilters.length;
        };
    }, [filters, isFilterActive]);

    /*-- filterable list setup --*/
    useEffect(() => {
        if (canBeFiltered && !activeProductList.length) {
            handleFilterReset();
            setActiveProductList(productList)
        }
    }, [handleFilterReset, productList, canBeFiltered, activeProductList]);

    useEffect(() => {
        if (canBeFiltered) {
            setActiveProductList(
                productList.filter(
                    p => p.filterLabels.some(t => activeFilterList.includes(t))
                )
            )
        }
    }, [productList, activeFilterList, canBeFiltered]);

    return (canBeFiltered
        ? {
            renderFilter: (sectionName) => <Filter
                categories={filters}
                activeFilters={activeFilterList}
                toggleLabel={handleFilter}
                reset={handleFilterReset}
                filterSection={sectionName} />,
            activeProductList,
            isFilterActive: isFilterActive
        }
        : {
            renderFilter: () => null,
            activeProductList: productList,
            isFilterActive: false
        });
}

useFilters.propTypes = {
    productList: PropTypes.arrayOf(PropTypes.shape({
        filterLabels: PropTypes.arrayOf(PropTypes.string)
    })),
}

export default useFilters;

const countFilterLabelsByCategory = (productList = []) => {
    const sortFilterLabelsByCount = (countedLabelsByCategory) => (Object.entries(countedLabelsByCategory).reduce((accFilterVals, [category, labelCountObject]) => {
        accFilterVals[category] = sortKeys(labelCountObject);

        return accFilterVals;
    }, {}));

    return sortFilterLabelsByCount(productList.reduce((labelCount, product) => {
        FILTER_CATEGORIES.forEach(category => {
            if (product.hasOwnProperty(category)) {
                product[category].forEach((label) => {
                    if (!labelCount.hasOwnProperty(category)) {
                        labelCount = { ...labelCount, [category]: {} };
                    }
                    if (!labelCount[category].hasOwnProperty(label)) {
                        labelCount[category][label] = 0;
                    }
                    labelCount[category][label] += 1;
                })
            }
        })
        return labelCount;
    }, {}))
};

export const useFilterTags = (product) => {
    const filters = countFilterLabelsByCategory([product]);

    return {
        hasFilterableTags: Object.keys(filters).length > 1,
        renderFilterTags: () => Object.keys(filters).map(category => (
            product[category].map(label => <FilterLabel key={label} filterName={label} filterCategory={category} />)
        ))
    }
}