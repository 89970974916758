import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Breadcrumb } from "react-bootstrap";
import { Link } from 'react-router-dom';

const NavBreadcrumb = ({ links = [] }) => {
    const [domReady, setDomReady] = useState(false);

    useEffect(() => {
        if (document.getElementById('nav-breadcrumb') && !domReady) {
            setDomReady(true);
        }
    }, [domReady])

    return domReady ? createPortal(
        <Breadcrumb role={"navigation"}>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
            {links.map(link => link.isActive
                ? <Breadcrumb.Item key={link.pageName} active>{link.pageName}</Breadcrumb.Item>
                : <Breadcrumb.Item key={link.pageName} linkAs={Link} linkProps={{ to: link.url }}>{link.pageName}</Breadcrumb.Item>)}
        </Breadcrumb>, document.getElementById('nav-breadcrumb')
    ) : null;
}

NavBreadcrumb.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        isActive: PropTypes.bool,
        url: PropTypes.string,
        pageName: PropTypes.string.isRequired
    }))
}

export default NavBreadcrumb;