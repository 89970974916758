import { GridLoader, PropagateLoader } from 'react-spinners';

export default function Loading() {
  return (
    <div id="center-wrapper">
      <GridLoader size={32} speedMultiplier={1.5} />
    </div>
  );
}

export const LoadingLine = ({ wrapperClassName }) => {
  return <div className={wrapperClassName}>
    <PropagateLoader size={24} speedMultiplier={1.5} />
  </div>
}