import PropTypes from 'prop-types';
import { formatDate } from 'lib/utils';
import { useMinScreenWidth, useFilterTags } from 'lib/hooks';

import { ExternalLink, PyPiLink, GithubLink } from 'components/common';
import { Card, Accordion } from 'react-bootstrap';
import styles from 'styles/card.module.scss';

import { featuredIcon } from 'assets/icons';
import * as productTypeIcons from 'assets/productTypeIcons';

const ResearchCard = ({ product }) => {
    const { isWiderThanMobile } = useMinScreenWidth();

    const renderCard = () => <Card as={"article"} className={styles.researchWrapper}>
        <ResearchCardHeader {...product} />
        <ResearchCardBody product={product} />
    </Card >

    return isWiderThanMobile ? renderCard() : <Accordion>{renderCard()}</Accordion>;
}

const ResearchCardHeader = ({ featured, type, title, image }) => {
    return <header className={styles.imageContainer}>
        <Card.Img src={`/images/${image}`} alt="Card image" variant="top" className={styles.headerImg} />
        <Card.ImgOverlay>
            {featured && (
                <span className={`${styles.productBadge} ${styles["productBadge--featured"]}`}>
                    <img src={featuredIcon} alt="Featured" className={styles.productBadge__icon} />
                </span>
            )}
            <span className={`${styles.productBadge} ${styles["productBadge--type"]}`}>
                {type} <img src={productTypeIcons[type]} alt={type} className={styles.productBadge__icon} />
            </span>
        </Card.ImgOverlay>
    </header>
}

const ResearchCardBody = ({ product }) => {
    const { isWiderThanMobile } = useMinScreenWidth();

    const renderHeadingArray = () => ([
        <Card.Title as={"h2"} className={styles.cardTitle}>{product.title}</Card.Title>,
        <ProductDate key="date" date={product.date} />,
        <Citation key="citation" product={product} />,
        <ExternalLink key="url" url={product.url} wrapperClassName={styles.subheadingItem} />,
        <PyPiLink key="pypi" pypi={product.pypi} wrapperClassName={styles.subheadingItem} />,
        <GithubLink key="gh" github={product.github} wrapperClassName={styles.subheadingItem} />
    ])

    return <Card.Body className={styles.bsCardBody}>
        {isWiderThanMobile
            ? <>
                {renderHeadingArray()}
                <FilterTags product={product} />
                <CardDescription {...product} />
            </>
            : <>
                <div className={styles.mobileToggle__heading}>
                    <div className={styles.mobileToggleHeading__contents}>{renderHeadingArray()}</div>
                    <Accordion.Button className={styles.mobileToggle__button}>Details</Accordion.Button>
                </div>
                <Accordion.Body className={styles.productSummary}>
                    <FilterTags product={product} />
                    <CardDescription {...product} />
                </Accordion.Body>
            </>
        }
    </Card.Body>;
}

/*----- Card Body Components -----*/
const AuthorLine = ({ cite_authors }) => cite_authors ? <span data-cite="authors">{`${cite_authors}.`}</span> : null;
const YearLine = ({ date }) => date ? <span data-cite="year">{`${new Date(date).getFullYear()}.`}</span> : null;
const TitleLine = ({ cite_title }) => cite_title ? <span data-cite="title"><cite>{cite_title}</cite>.</span> : null;
const JournalLine = ({ journal }) => journal ? <span data-cite="journal" className={styles["cite--italic"]}>{journal}</span> : null;
const VolumeLine = ({ volume }) => volume ? <span data-cite="vol" className={styles["cite--bold"]}>{volume}</span> : null;
const NumberLine = ({ number }) => number ? <span data-cite="number">{`(${number})`}</span> : null;
const PageLine = ({ page }) => page ? <span data-cite="page" className={styles["cite--page"]}>{`${page}`}</span> : null;
const LocationLine = ({ location }) => location ? <span data-cite="location" className={styles["cite--italic"]}>{location}</span> : null
const DoiLine = ({ doi }) => doi
    ? <ExternalLink
        url={`https://doi.org/${doi}`}
        labelText={"doi"}
        linkText={doi}
        wrapperClassName={styles.doi} />
    : null;
const VersionLine = ({ version }) => version ? <span data-cite="version">{`Version ${version}`}</span> : null;

const Citation = ({ product }) => {
    const citationArray = []

    switch (product.type) {
        case 'software':
            citationArray.push(<VersionLine {...product} key="version" />);
            break;
        case 'dataset':
            citationArray.push(<DoiLine {...product} key="doi" />);
            break
        case 'article':
        case 'report':
        case 'preprint':
            citationArray.push(<AuthorLine {...product} key="authors" />);
            citationArray.push(<YearLine {...product} key="year" />);
            citationArray.push(<TitleLine {...product} key="title" />);
            if (product.type === 'article') {
                citationArray.push(<JournalLine {...product} key="journal" />);
                citationArray.push(<VolumeLine {...product} key="volume" />);
                citationArray.push(<NumberLine {...product} key="number" />);
                citationArray.push(<PageLine {...product} key="page" />);
            } else {
                citationArray.push(<LocationLine {...product} key="location" />);
            }
            citationArray.push(<DoiLine {...product} key="doi" />);
            break;
        default:
            return;
    }

    return <div className={`${styles.subheadingItem} ${styles.citationWrapper}`}>{citationArray}</div>
}

const ProductDate = ({ date }) => (date
    ? <div className={styles.subheadingItem} key="date"><b>{formatDate(date)}</b></div>
    : null);

const FilterTags = ({ product }) => {
    const { hasFilterableTags, renderFilterTags } = useFilterTags(product);

    return hasFilterableTags ? <div className={styles.productTags}>{renderFilterTags()}</div> : null;
}

const CardDescription = ({ summary, id }) => <div className={styles.productSummary}>
    {Array.isArray(summary)
        ? summary.map((paragraph, i) => <Card.Text key={`${id}.summary.${i}`}>{paragraph}</Card.Text>)
        : <Card.Text key={`${id}.summary`}>{summary}</Card.Text>}
</div>;

ResearchCard.propTypes = {
    product: PropTypes.shape({
        authors: PropTypes.arrayOf(PropTypes.string),
        cite_authors: PropTypes.string,
        cite_title: PropTypes.string,
        date: PropTypes.string,
        doi: PropTypes.string,
        featured: PropTypes.bool,
        github: PropTypes.string,
        id: PropTypes.string.isRequired,
        image: PropTypes.string,
        journal: PropTypes.string,
        number: PropTypes.string,
        page: PropTypes.string,
        pypi: PropTypes.string,
        summary: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['article', 'report', 'preprint', 'dataset', 'software', 'tool']).isRequired,
        version: PropTypes.string,
        volume: PropTypes.string,
    }),
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
}

export default ResearchCard;
