import { useEffect, useState, useCallback } from "react";

import useAxios from 'axios-hooks';
// import { formatResearchData, sortResearchProducts } from 'lib/researchDataUtils';
// import { sortArrayByMostRecentDateKey } from 'lib/utils';
import { FILTER_CATEGORIES } from "lib/vars";

const useResearchData = () => {
  const [products, setProducts] = useState([]);
  const [{ data, loading, error }] = useAxios('research.json');

  useEffect(() => {
    if (!loading && data.hasOwnProperty("products")) {
      const formattedData = formatResearchData(data);
      setProducts(formattedData);
    }
  }, [data, loading])

  const memoizedSort = useCallback(productList => sortResearchProducts(productList), [])

  return { products, sortResearchProducts: memoizedSort, error, loading }
}

export const sortResearchProducts = (products = []) => {
  const featuredProducts = [];
  const allProducts = [];

  products.forEach(p => {
    if (p.featured) {
      featuredProducts.push(p)
    } else {
      allProducts.push(p);
    }
  });

  return {
    featuredProducts,
    allProducts,
  }

}

const formatResearchData = (data = {}) => {
  if (!data.hasOwnProperty("products")) {
    return null;
  }
  data.products.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  data.products.forEach(prod => {
    prod.filterLabels = FILTER_CATEGORIES.reduce((labels, cat) => {
      if (prod.hasOwnProperty(cat)) {
        labels.push(...prod[cat])
      }
      return labels;
    }, [])
  })

  return data.products
}

export default useResearchData;