import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { MainNav, MainFooter } from 'components/layout'
import { Research, Sockit } from 'components/pages';

export default function App() {

  return (
    <HashRouter>
      <MainNav />
      <Switch>
        <Route path={'/sockit/:results?'} >
          <Sockit />
        </Route>
        <Route exact path={'/'}>
          <Research />
        </Route>
        <Route>
          <Redirect to={"/"} />
        </Route>
      </Switch>
      <MainFooter />
    </HashRouter>
  );
}