import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import styles from 'styles/card.module.scss';
import * as logos from 'assets/logos';

const ToolCard = ({ product }) => {

    return <Card className={`${styles.researchWrapper} ${styles.toolCard}`}>
        <header className={styles.imageContainer}>
            <Card.Img src={logos[product.id]} alt={`${product.title} logo`} variant="top" className={styles.headerImg} />
        </header>
        <Card.Body className={styles.bsCardBody}>
            <p className={styles.productSummary}>
                {product.description}
            </p>
        </Card.Body>
        <Link to={product.url} className="btn">
            {`Try ${product.title} \u2192`}
        </Link>
    </Card>
}

ToolCard.propTypes = {
    product: PropTypes.shape({
        url: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
    })
}

export default ToolCard;