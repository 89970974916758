import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Offcanvas, Popover, OverlayTrigger } from 'react-bootstrap';
import { FilterLabel } from 'components/common';
import { useMinScreenWidth } from 'lib/hooks';
import styles from 'styles/filter.module.scss';
import { filterIcon } from 'assets/icons';

const Filter = ({
  categories, activeFilters, toggleLabel, reset, filterSection = ""
}) => {
  const { isWiderThanMobile } = useMinScreenWidth();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => {
    if (!isWiderThanMobile) {
      setIsOpen(true);
    }
  }

  const headerTitle = filterSection ? `Filter ${filterSection}` : "Filter";
  const makeFilterMenuBody = () => Object.entries(categories).map(([catName, labels]) => (
    <section key={catName}>
      <h2 className={styles.title}>{catName.charAt(0).toUpperCase() + catName.slice(1)}</h2>
      <div className={styles.container}>
        {labels.map(label => (
          <FilterLabel
            key={label}
            filterName={label}
            filterCategory={catName}
            toggleFunction={() => toggleLabel(label)}
            isActive={activeFilters.includes(label)} />
        ))}
      </div>
    </section>
  ));

  const filterButton = (<Button variant={"outline-secondary"} onClick={handleShow} key="filterButton" >
    <span>Filter</span>
    <img src={filterIcon} alt="Filter" />
  </Button>)

  const resetButton = <Button variant="outline-secondary" onClick={reset}>Reset</Button>;

  const popover = (<Popover className={styles.filtersWrapper}>
    <Popover.Header className={styles.filtersTitle}>
      {headerTitle}
    </Popover.Header >
    <Popover.Body className={styles.filters}>
      {makeFilterMenuBody()}
      {resetButton}
    </Popover.Body>
  </Popover>)

  const offcanvas = (<Offcanvas
    key="filterMenu"
    show={isOpen}
    onHide={handleClose}
    placement={"end"}
    className={styles.filtersWrapper}>
    <Offcanvas.Header className={styles.filtersTitle} closeButton>
      {headerTitle}
    </Offcanvas.Header>
    <Offcanvas.Body className={styles.filters}>
      {makeFilterMenuBody()}
      {resetButton}
    </Offcanvas.Body>
  </Offcanvas>)

  return isWiderThanMobile
    ? <OverlayTrigger trigger="click" placement="bottom-end" rootClose={true} overlay={popover}>
      {filterButton}
    </OverlayTrigger>
    : [filterButton, offcanvas];
}

Filter.propTypes = {
  categories: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  toggleLabel: PropTypes.func,
  reset: PropTypes.func,
  filterSection: PropTypes.string
}

export default Filter;