import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import * as ROUTES from 'lib/routes';

import useAxios from 'axios-hooks';
import { lotsOfResults as testData } from '__mocks__/data/sockit';

import { Accordion, Card } from 'react-bootstrap';
import { NavBreadcrumb } from 'components/layout';
import { LoadingLine as Loading, GithubLink, PyPiLink, ErrorMessage } from 'components/common';
import SockitResultCard from 'components/pages/sockit/SockitResultCard';
import { SOCKIT_VERSION_NUMBER } from 'lib/vars';
import { sockit as sockitLogo } from 'assets/logos';
import styles from 'styles/sockit.module.scss';

const Sockit = () => {

    const { push: pushToHistory } = useHistory();

    const [searchTerm, setSearchTerm] = useState('');
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [results, setResults] = useState([]);
    const [{
        data: socData,
        loading,
        error },
        refetch,
        cancelRequest] = useAxios({
            url: process.env.REACT_APP_SOCKIT_ENDPOINT,
            method: 'POST',
            data: { title: searchTerm }
        }, {
            manual: true
        });

    const handleInput = (e) => {
        setSearchTerm(e.target.value);
        if (results.length) {
            setResults([]);
        }
        if (wasSubmitted) {
            setWasSubmitted(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResults([]);
        if (process.env.REACT_APP_ENVIRONMENT === "dev" && searchTerm.toLowerCase() === "test") {
            cancelRequest();
            setResults(testData.socs);
            setWasSubmitted(true);
        } else {
            try {
                await refetch();
            } catch (e) {
                cancelRequest();
            }
            finally {
                setWasSubmitted(true);
            }
        }
    }

    useEffect(() => {
        if (!results.length && !loading) {
            if (socData !== undefined && socData.hasOwnProperty("socs")) {
                if (socData.socs.length) {
                    setResults(socData.socs);
                } else {
                    setResults(["none"]);
                }
            }
        }
    }, [socData, results, loading])

    useEffect(() => {
        if (wasSubmitted) {
            pushToHistory(`/${ROUTES.SOCKIT.RESULTS}`);
        } else {
            pushToHistory(`/${ROUTES.SOCKIT.HOME}`)
        }
    }, [wasSubmitted, pushToHistory])

    const displaySocResults = () => {
        if (loading) {
            return <Loading wrapperClassName={styles.resultsLoading} />;
        } else if (wasSubmitted) {
            const noResults = !results.length || results[0] === "none";
            return <>
                <label id="output-label" htmlFor={"sockitResults"} className={`${styles.label} ${styles.outputLabel}`}>{`Results${noResults ? "" : ` (${results.length})`}`}</label>
                <output form={"sockitForm"} htmlFor={"jobTitle"} aria-labelledby={"output-label"} name={"sockitResults"} aria-live={"polite"}>
                    {noResults
                        ? (error
                            ? <ErrorMessage error={error} />
                            : <Card className={styles.noResults}>Sorry, we didn't find any SOC codes for that job title.  Please try something else.</Card>
                        )
                        : <ul>{results.map(soc => <Accordion as={"li"} key={soc.soc}>
                            <SockitResultCard soc={soc} />
                        </Accordion>)}
                        </ul>
                    }
                </output>
            </>
        }
    }

    return <>
        <NavBreadcrumb links={[{ isActive: true, pageName: "Sockit" }]} />
        <header className={styles.header}>
            <div className={styles.headerImage}>
                <img alt="Sockit Logo" src={sockitLogo} />
            </div>
            <span className={styles.versionNumber}>{`v${SOCKIT_VERSION_NUMBER}`}</span>
        </header>
        <main id="content" className={styles.content}>
            <p className={styles.description}><strong>Sockit</strong> is a self-contained toolkit for assigning probabilistic Standard Occupational Classification (SOC) codes to free-text job titles. You can install it as a Python package or try it out below!</p>
            <GithubLink
                github={"https://github.com/ripl-org/sockit"}
                linkText={"View source code on GitHub"}
                wrapperClassName={`${styles.link} ${styles.link1}`} />
            <PyPiLink
                pypi={`https://pypi.org/project/sockit/${SOCKIT_VERSION_NUMBER}/`}
                linkText={"Install Python package from PyPI"}
                wrapperClassName={`${styles.link} ${styles.link2}`} />
            <form id={"sockitForm"} onSubmit={handleSubmit}>
                <label className={styles.label} htmlFor="jobTitle">Enter a job title:</label>
                <input className={styles.mainInput} id="jobTitle" name="jobTitle" type="text" onChange={handleInput}></input>
                <input className={`${styles.label} ${styles.submitButton}`} type="submit" value="Sockit!" disabled={searchTerm.length <= 0 && !results.length} />
            </form>
            {displaySocResults()}
        </main>
    </>
}

export default Sockit;