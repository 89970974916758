import PropTypes from 'prop-types';

import styles from 'styles/common.module.scss';

const CardGallery = ({ sectionTitle, itemList = [], totalItems=undefined, isFilterActive = false, sectionAnchor, Card }) => {

    const itemCount = totalItems || itemList.length;
    const countDisplay = `(${isFilterActive ? `${itemList.length}/${itemCount}` : itemCount})`;


    return <section className={styles.cardGalleryWrapper}>
        <header id={sectionAnchor}>
            <h1>{sectionTitle}<span>{countDisplay}</span></h1>
        </header>
        <ul className={styles.gallery}>
            {itemList.map(product => (
                <li key={product.id || product.title} className={styles.frame}>
                    <Card product={product} />
                </li>))}
        </ul>
    </section>
}

CardGallery.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    itemList: PropTypes.array,
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    sectionAnchor: PropTypes.string.isRequired,
    Card: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

export default CardGallery;