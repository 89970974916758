import cn from 'classnames';
import styles from 'styles/filter.module.scss';

const FilterLabel = ({ filterName, filterCategory, toggleFunction = undefined, isActive = true }) => {
  const isClickable = !!toggleFunction;
  const Element = isClickable ? "button" : "span";

  return <Element
      onClick={toggleFunction}
      className={cn({
          [styles.filter]: true,
          [styles[filterCategory.toLowerCase()]]: true,
          [styles.clickable]: isClickable,
          [styles.filterOff]: !isActive
      })}>
      {filterName}
  </Element>
};

export default FilterLabel;