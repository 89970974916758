import { useState, useEffect } from 'react';
import { arrayChunker } from 'lib/utils';
import { Pagination, Button } from 'react-bootstrap';
import styleVars from 'styles/vars.module.scss';

const customPaginationStyle = `
.pagination {
    display: inline-flex;
    margin-bottom: 0;
}

.page-link {
    color: ${styleVars.darkBlue};
    border-color: ${styleVars.mediumGray};
}

.page-item.disabled .page-link {
    color: ${styleVars.secondary};
    background-color: ${styleVars.lightGray};
}

.page-item.active .page-link {
    background-color: ${styleVars.darkBlue};
    border-color: ${styleVars.darkBlue};
    cursor: default;
]`;

const usePagination = ({ maxItemsPerPage = 1, itemArray = [], renderItemArray }) => {

    const [pageCount, setPageCount] = useState(false);
    const [pages, setPages] = useState([]);
    const [currentPageIdx, setCurrentPageIdx] = useState(0);
    const [paginationItemProps, setPaginationItemProps] = useState([]);
    const [isPaginationActive, setIsPaginationActive] = useState(true);

    //determine if pagination is necessary
    useEffect(() => {
        setPageCount(Math.ceil(itemArray.length / maxItemsPerPage))
    }, [maxItemsPerPage, itemArray])

    //dynamically set page array and pagination bar props
    useEffect(() => {
        if (pageCount !== pages.length) {
            const chunkedPages = arrayChunker(itemArray, maxItemsPerPage);
            setPages(chunkedPages);
            setPaginationItemProps(chunkedPages.map((page, idx) => ({
                key: idx,
                id: idx,
                active: isPaginationActive ? idx === currentPageIdx : false,
                onClick: () => { setCurrentPageIdx(idx) },
                children: `${idx + 1}`
            })));
        }
    }, [pageCount, pages, itemArray, maxItemsPerPage, currentPageIdx, isPaginationActive])

    //update clicked page
    useEffect(() => {
        if (currentPageIdx === undefined && isPaginationActive) {
            setIsPaginationActive(false);
            setPaginationItemProps(prevState => {
                return prevState.reduce((newState, current) => {
                    newState.push({ ...current, active: false });
                    return newState;
                }, [])
            })
        } else if (currentPageIdx !== undefined && (paginationItemProps.length && !paginationItemProps[currentPageIdx].active)) {
            setIsPaginationActive(true);
            setPaginationItemProps(prevState => {
                return prevState.reduce((newState, current, idx) => {
                    newState.push({ ...current, active: idx === currentPageIdx });
                    return newState;
                }, [])
            })
        }
    }, [paginationItemProps, currentPageIdx, isPaginationActive])

    return pageCount > 1
        ? {
            PaginationBar: ({ className }) => (<>
                <style type="text/css">{customPaginationStyle}</style>
                <Pagination className={className}>
                    <Pagination.Prev disabled={!currentPageIdx > 0} />
                    {paginationItemProps.map(itemProps => <Pagination.Item {...itemProps} />)}
                    <Pagination.Next disabled={!isPaginationActive || (currentPageIdx === paginationItemProps.length - 1)} />
                </Pagination>
            </>),
            currentPageItemArray: isPaginationActive
                ? [renderItemArray(pages[currentPageIdx])]
                : [renderItemArray(itemArray)],
            PaginateNoneButton: ({ className = "" }) => <Button variant="outline-secondary" className={className} disabled={!isPaginationActive} onClick={() => setCurrentPageIdx(undefined)}>See All</Button>
        }
        : {
            PaginationBar: () => null,
            currentPageItemArray: [renderItemArray(itemArray)],
            PaginateNoneButton: () => null,
        };
}

export default usePagination;