import { useState, useEffect } from "react";
import breakpoints from 'styles/vars.module.scss';

const useMinScreenWidth = () => {
    const [isWiderThanMobile, setIsWiderThanMobile] = useState(false);
    const [isWiderThanTablets, setIsWiderThanTablets] = useState(false);
    const [isWiderThanDesktops, setIsWiderThanDesktops] = useState(false);
    const [isWidest, setIsWidest] = useState(false);


    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const mobileMinQuery = window.matchMedia(`(min-width: ${breakpoints.smallDevices})`);
        const handleMobileResize = ({ matches = false }) => setIsWiderThanMobile(matches);
        handleMobileResize({ matches: mobileMinQuery.matches });
        mobileMinQuery.addEventListener("change", handleMobileResize);

        const tabletMinQuery = window.matchMedia(`(min-width: ${breakpoints.tablets})`);
        const handleTabletResize = ({ matches = false }) => setIsWiderThanTablets(matches);
        handleTabletResize({ matches: tabletMinQuery.matches });
        tabletMinQuery.addEventListener("change", handleTabletResize);

        const desktopMinQuery = window.matchMedia(`(min-width: ${breakpoints.desktops})`);
        const handleDesktopResize = ({ matches = false }) => setIsWiderThanDesktops(matches);
        handleDesktopResize({ matches: desktopMinQuery.matches });
        desktopMinQuery.addEventListener("change", handleDesktopResize);

        const largeScreenMinQuery = window.matchMedia(`(min-width: ${breakpoints.largeScreens})`);
        const handleLargeScreenResize = ({ matches = false }) => setIsWidest(matches);
        handleLargeScreenResize({ matches: largeScreenMinQuery.matches });
        largeScreenMinQuery.addEventListener("change", handleLargeScreenResize);


        return () => {
            mobileMinQuery.removeEventListener("change", handleMobileResize);
            tabletMinQuery.removeEventListener("change", handleTabletResize);
            desktopMinQuery.removeEventListener("change", handleDesktopResize);
            largeScreenMinQuery.removeEventListener("change", handleLargeScreenResize);
        }
    }, [])

    return { isWiderThanMobile, isWiderThanTablets, isWiderThanDesktops, isWidest };
}

export default useMinScreenWidth;