import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import styles from "styles/common.module.scss";

const ErrorMessage = ({ error }) => {
    const headerText = error.response.status ? `${error.response.status} Error` : "Error";

    return <div className={styles.errorContainer}>
        <Card className={styles.errorMessage} role="alert">
        <Card.Header as="header">
            <Card.Title>{headerText}</Card.Title>
        </Card.Header>
        <Card.Body className={styles.body}>
            <p>An error occurred while processing your request.</p>
            {error.message && <p>({error.message}.)</p>}
        </Card.Body>
    </Card>
    </div>;
}

ErrorMessage.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
        response: PropTypes.shape({
            status: PropTypes.number,
        })
    })
}

export default ErrorMessage;

