//turns a object into an array of its keys organized by their value in descending order 
export const sortKeys = obj => {
    return Object.entries(obj).sort((a, b) => b[1] - a[1]).reduce((result, entry) => {
        result.push(entry[0]);
        return result;
    }, []);
};

export const formatDate = (d = "") => {
    const months = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
    }
    if (d.length === 10) {
        return d.substring(8, 10) + " " + months[d.substring(5, 7)] + " " + d.substring(0, 4);
    } else if (d.length === 7) {
        return months[d.substring(5, 7)] + " " + d.substring(0, 4);
    } else if (d.length <= 4) {
        return d;
    }
};

export const arrayChunker = (arr, chunkSize) => {
    const chunked = [];
    let index = 0;
    while (index < arr.length) {
        chunked.push(arr.slice(index, chunkSize + index));
        index += chunkSize;
    }
    return chunked;
}

export const sortArrayByMostRecentDateKey = (arr) => arr.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
});