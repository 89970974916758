import PropTypes from 'prop-types';
import styles from 'styles/common.module.scss';
import { linkIcon, sourceControlIcon, pythonIcon } from 'assets/icons';

const ExternalLink = ({
    url,
    labelText = "",
    imgSrc,
    imgAlt,
    imgClassName,
    linkText,
    wrapperClassName }) => {

    const formatContents = () => {
        let anchorText = linkText || url;

        let Label = null;
        let linkContents = <span key="link">{anchorText}</span>;

        if (labelText) {
            Label = <span key="label" className={styles.label}>{labelText}:</span>;
        } else if (imgSrc) {
            Label = <img
                key="labelImg"
                src={imgSrc}
                alt={imgAlt || "external link"}
                className={imgClassName || styles.icon} />;
        } else {
            linkContents = <span key="link" className={styles.linkContentsWrapper}>
                <img
                    src={linkIcon}
                    alt={"external link"} />
                {linkContents}
            </span>
        }
        return [
            Label,
            <a key="link" href={url} target="_window">{linkContents}</a>
        ];
    }

    return (url
        ? <div className={`${styles.externalLink}${wrapperClassName ? ` ${wrapperClassName}` : ""}`}>
            {formatContents()}
        </div>
        : null)
}

ExternalLink.propTypes = {
    wrapperClassName: PropTypes.string,
    url: PropTypes.string,
    labelText: PropTypes.string,
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgClassName: PropTypes.string,
    linkText: PropTypes.string
}

export default ExternalLink;

export const PyPiLink = ({ pypi, linkText = "", wrapperClassName = "" }) => (pypi
    ? <ExternalLink
        url={pypi}
        imgSrc={pythonIcon}
        imgAlt={"Python Package Index (PyPI)"}
        imgClassName={styles.pypi}
        wrapperClassName={wrapperClassName}
        linkText={linkText || pypi} />
    : null
);

export const GithubLink = ({ github, linkText = "", wrapperClassName = "" }) => (github
    ? <ExternalLink
        url={github}
        imgSrc={sourceControlIcon}
        imgAlt={"GitHub repository"}
        imgClassName={styles.github}
        wrapperClassName={wrapperClassName}
        linkText={linkText || github} />
    : null
);