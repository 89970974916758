import { useEffect, useState } from 'react';
import { toolList } from 'lib/toolDataUtils';
import { useFilters, useResearchData } from 'lib/hooks';
import { Loading, ErrorMessage, CardGallery } from 'components/common';
import ResearchCard from 'components/pages/research/ResearchCard';
import ToolCard from 'components/pages/research/ToolCard';

import styles from 'styles/research.module.scss';

export default function Research() {

  const { products, sortResearchProducts, error, loading } = useResearchData();
  const [activeFeaturedProducts, setActiveFeaturedProducts] = useState([]);
  const [activeAllProducts, setActiveAllProducts] = useState([]);

  const { activeProductList, renderFilter, isFilterActive } = useFilters(products);
  const { featuredProducts, allProducts } = sortResearchProducts(products);

  useEffect(() => {
    if (activeProductList.length !== (activeFeaturedProducts.length + activeAllProducts.length)) {
      const { featuredProducts: f_products, allProducts: a_products } = sortResearchProducts(activeProductList)
      setActiveFeaturedProducts(f_products);
      setActiveAllProducts(a_products);
    }
  }, [activeProductList, isFilterActive, activeFeaturedProducts.length, activeAllProducts.length, sortResearchProducts])


  const renderResearchContents = () => {
    if (loading) {
      return <Loading />
    } else if (error) {
      return <ErrorMessage error={error} />
    } else {
      return <>
        <div className={`${styles.filterWrapper} ${isFilterActive ? styles.filterWrapper__active : ""}`}>
          {renderFilter("Research")}
        </div>
        {featuredProducts.length && <CardGallery
          sectionTitle={"Featured Research"}
          sectionAnchor={"featured-research"}
          itemList={activeFeaturedProducts}
          totalItems={featuredProducts.length}
          isFilterActive={isFilterActive}
          Card={ResearchCard} />}

        <CardGallery
          sectionTitle={"Featured Tools"}
          sectionAnchor={"featured-tools"}
          itemList={toolList}
          Card={ToolCard} />

        {allProducts.length && <CardGallery
          sectionTitle={"All Research"}
          sectionAnchor={"all-research"}
          itemList={activeAllProducts}
          totalItems={allProducts.length}
          isFilterActive={isFilterActive}
          Card={ResearchCard} />}
      </>
    }
  }

  return <main id="content" className={styles.content}>
    {renderResearchContents()}
  </main>;
};
